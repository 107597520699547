<template>
  <v-dialog max-width="500"
            scrollable
            v-model="dialog"
            persistent>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-btn text
             v-bind="attrs"
             v-on="{...menu }"
             color="grey">
        <v-icon left>mdi-tune</v-icon>
        Filter
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Filtrer par
        <v-spacer/>
        <v-btn small outlined text class="mr-3 ml-3 no-anim" @click="clearFilter">Effacer</v-btn>
        <v-icon @click="dialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-select
                prepend-icon="mdi-filter-outline"
                multiple chips deletable-chips small-chips dense clearable
                :items="suppliers"
                v-model="filter.selectedsuppliers"
                :item-text="'name'"
                :item-value="'id'"
                label="Suppliers..." hide-details></v-select>
          </v-col>

          <v-col cols="12">
            <v-select
                prepend-icon="mdi-filter-outline"
                multiple chips deletable-chips small-chips dense clearable
                :items="owners"
                v-model="filter.selectedowners"
                :item-text="'name'"
                :item-value="'id'"
                label="Createur..." hide-details></v-select>
          </v-col>

          <v-col cols="12">
            <v-select v-model="filter.status"
                      prepend-icon="mdi-filter-outline"
                      multiple chips deletable-chips small-chips dense clearable
                      :items="[
                            {   
                                value: 1,
                                label : 'Utilisé'
                            },
                            {   
                                value: 0,
                                label : 'Non Utilisé'
                            }
                       ]"
                      :item-text="'label'"
                      :item-value="'value'"
                      label="Status..." hide-details></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="filter.date"
                          prepend-icon="mdi-filter-outline"
                          append-icon="mdi-calendar"
                          type="date"
                          dense clearable
                          label="Créer aprés le.." hide-details></v-text-field>
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary"
               :disabled="isDisabled"
               depressed block @click="handleFilter">
          Recherche
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  props: ['filter'],
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    handleFilter() {
      this.dialog = false
      this.$emit('filter', this.filter)
    },
    clearFilter() {
      this.props.filter = {}
    }
  },
  computed: {
    isDisabled() {
      return Object.values(this.filter).every(x => x === null || x === '' || x.length === 0)
    },
    suppliers() {
      return this.$store.getters.getSuppliers;
    },
    owners() {
      return this.$store.getters.getVoucherOwners;
    }
  }
}
</script>


<style scoped>
.no-anim:hover {
  transform: none;
}
</style>