<template>
  <v-dialog v-model="dialog"
            max-width="500"
            persistent
            scrollable>

    <template v-slot:activator="{ on: menu }">
      <v-btn color="gifty"
             dark
             depressed
             @click="errors = {}"
             v-on="{...menu }">
        <v-icon left>mdi-plus</v-icon>
        Nouveau voucher
      </v-btn>
    </template>

    <v-card :disabled="isLoading"
            :loading="isLoading">

      <v-card-title>
        Nouveau voucher
        <v-spacer/>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>

      <v-divider/>

      <v-card-text class="pa-8">

        <v-switch v-model="form.automatic_generation"
                  :error-messages="errors.automatic_generation"
                  label="Génération automatique"/>

        <v-text-field v-if="form.automatic_generation"
                      v-model="form.generation_count"
                      :error-messages="errors.generation_count"
                      clearable
                      label="Entrez le nombre de vouchers que vous souhaitez générer *"
                      type="number"/>

        <v-text-field v-if="!form.automatic_generation"
                      v-model="form.code"
                      :error-messages="errors.code"
                      clearable
                      label="Code *"
                      type="text"
        ></v-text-field>

        <v-alert v-if="form.automatic_generation" border="left" dense text type="info">
          <span class="font-weight-medium"> Remarque :</span>
          Le code est généré automatiquement.
        </v-alert>

        <v-select v-model="form.service_id"
                  :disabled="loadingService"
                  :error-messages="errors.service_id"
                  :item-text="'name'"
                  :item-value="'id'"
                  :items="services"
                  :loading="loadingService"
                  chips
                  clearable
                  deletable-chips
                  label="Service *"
                  small-chips
        >
          <template v-slot:item="slotProps">
            <v-avatar class="mr-2" size="35">
              <v-img :src="getImageLink(slotProps.item.image)" contain/>
            </v-avatar>
            {{ slotProps.item.name }}
          </template>
        </v-select>

        <v-text-field v-model="form.amount"
                      :error-messages="errors.amount"
                      clearable
                      label="Montant *"
                      type="number"
                      @focus=" errors && errors.amount ? (errors.amount = '') : false"
        ></v-text-field>

        <v-text-field v-model="form.displayed_amount"
                      :error-messages="errors.displayed_amount"
                      clearable
                      label="Montant à afficher"
                      @focus="errors && errors.displayed_amount ? (errors.displayed_amount = '') : false"
        ></v-text-field>

        <v-menu v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="auto"
                offset-y
                transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="form.expiration_date"
                          :error-messages="errors.expiration_date"
                          label="Date d’expiration *"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="form.expiration_date"
                         :min="dateNow"
                         @input="menu = false"
          ></v-date-picker>
        </v-menu>

      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-spacer/>
        <v-btn :disabled="isDisabled"
               color="primary"
               depressed
               @click="handleStore"
        >
          Sauvegarder
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import {HTTP} from "@/http-common";
import moment from "moment/moment";

export default {
    data() {
        return {
            services: [],
            dateNow: moment().format('YYYY-MM-DD'),
            loadingService: false,
            menu: false,
            dialog: false,
            form: {
                generation_count: 1,
                automatic_generation: false,
            },
            isLoading: false,
            errors: [],
        };
    },
    methods: {
        getImageLink(file) {
            return process.env.VUE_APP_FILE_URL + file;
        },
        handleStore() {
            this.isLoading = true;
            this.errors = [];
            HTTP.post("/manager/vouchers/create", this.form, {params: {}})
                .then((res) => {
                    this.$successMessage = "Cette opération a été enregistrée avec succès."
                    this.isLoading = false;
                    this.form = {}
                    this.close();
                    this.$emit("refresh");
                }).catch((err) => {
                this.isLoading = false;
                this.errors = err.response.data.errors;
            });
        },
        close() {
            this.dialog = false;
        },
        getServices() {
            this.loadingService = true;
            HTTP.get("/service/voucher", {params: {}}).then((res) => {
                this.services = res.data;
                this.loadingService = false;
            }).catch((err) => {
                this.loadingService = false;
                console.log(err);
            });
        },
    },
    computed: {
        isDisabled() {
            return (
                Object.values(this.form).every(
                    (x) => x === null || x === "" || x.length === 0
                ) || this.isLoading
            );
        },
    },
    mounted() {
        this.getServices();
    },
};
</script>

<style scoped>
</style>

<style scoped>
</style>