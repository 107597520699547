<template>
    <!--Dialogs-->
    <v-dialog v-model="uploadDialog" width="500" persistent>
        <form @submit.prevent="upload" enctype="multipart/form-data">
          <v-card>
            <v-card-title>
              Télécharger une liste de vouchers
              <v-spacer />
              <v-btn
                icon
                class="v-btn--active"
                color="primary"
                @click="uploadDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>

                    <v-select
                        prepend-icon="mdi-filter-outline"
                        chips deletable-chips small-chips dense clearable
                        :items="suppliers"
                        v-model="form.supplier_id"
                        :item-text="'name'"
                        :item-value="'id'"
                        label="Suppliers..."
                        @change="supplierChanged"
                        @focus="
                        uploadErrors && uploadErrors.supplier_id
                            ? (uploadErrors.supplier_id = '')
                            : false
                        "
                        :error-messages="uploadErrors.supplier_id"
                        >
                        <template v-slot:item="slotProps">
                            <div style="width: 50px;">
                                 <img :src="getImageLink(slotProps.item.image)" 
                                max-height="25"
                                max-width="25" width="25" height="25" class="p-2" />
                            </div>
                           
                            {{slotProps.item.name}}
                        </template>
                </v-select>

                <v-select
                  :loading="servicesLoading"
                        prepend-icon="mdi-filter-outline"
                         dense clearable
                        :items="services"
                        v-model="form.service_id"
                        :item-text="'name'"
                        :item-value="'id'"
                        label="Service..." 
                        @focus="
                        uploadErrors && uploadErrors.service_id
                            ? (uploadErrors.service_id = '')
                            : false
                        "
                        :error-messages="uploadErrors.service_id"
                    >
                        <template v-slot:item="slotProps">
                            <div style="width: 50px;">
                                 <img :src="getImageLink(slotProps.item.image)" 
                                max-height="25"
                                max-width="25" width="25" height="25" class="p-2" />
                            </div>
                           
                            {{slotProps.item.name}}
                        </template>
                </v-select>
              
              <v-file-input
                label="Fichier *"
                show-size
                suffix="Excel"
                @change="setExceFile"
                @focus="
                  uploadErrors && uploadErrors.excel_file
                    ? (uploadErrors.excel_file = '')
                    : false
                "
                :error-messages="uploadErrors.excel_file"
                ref="file"
                chips
                
              />
              <v-btn
                color="primary"
                :loading="btnUploadLoading"
                depressed
                class="rounded-lg text-none mt-3"
                large
                type="submit"
                @click="handleUpload"

              >
                <v-icon left>mdi-upload-outline</v-icon>
                Télécharger
              </v-btn>
            </v-card-text>
          </v-card>
        </form>
      </v-dialog>
</template>

<script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      form: {
        excel_file: "",

        supplier_id: null,
        // supplier has many services
        service_id: null,
      },
      uploadDialog: false,
      btnUploadLoading: false,
      uploadErrors: [],
      servicesLoading: false,
      services: [],
    };
  },
  methods: {
    setExceFile(file) {
      this.form.excel_file = file;
    },
    open() {
      this.uploadDialog = true;
    },
    handleUpload() {
      this.btnUploadLoading = true;
    },
    upload() {
      this.btnUploadLoading = true;
      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });
      HTTP.post("manager/vouchers/import_excel", formData)
        .then(() => {
          this.$successMessage = "Cette liste a été enregistrée avec succès";
          this.btnUploadLoading = false;
          this.form = {};
          this.$refs.file.reset();
          this.uploadDialog = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          this.btnUploadLoading = false;
          this.uploadErrors = err.response.data.errors;
          console.log(err);
        });
    },
    getImageLink(file) {
      return process.env.VUE_APP_FILE_URL + file;
    },

    supplierChanged() {
      var supplier = this.form.supplier_id;
      this.servicesLoading = true;

      HTTP.get("manager/vouchers/supplier/" + supplier + "/services")
        .then((res) => {
          this.services = res.data;
          this.servicesLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.servicesLoading = false;
        });
    },
  },
  computed: {
    suppliers() {
      return this.$store.getters.getSuppliers;
    },
  },

  mounted() {},
};
</script>