<template>
  <v-container fluid>
    <div class="main">

      <Statistiques ref="statistiques"/>

      <div class="d-flex justify-space-between my-4">
        <create-dialog @refresh="[refresh()]"/>
        <CreateRefillVouchers @refresh="refresh" v-if="can('admin')"/>
        <v-spacer></v-spacer>
        <v-btn text
               class="v-btn--active"
               color="gifty"
               to="/analyses_vouchers/stock">
          <v-icon left>mdi-chart-box</v-icon>
          Voir le stock
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </div>

      <v-row v-if="isLoading">
        <v-col cols="12">
          <v-card class="rounded-lg" flat>
            <v-skeleton-loader type="table"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12">
          <v-card class="rounded-lg shadow">

            <v-card-title>

              <div>
                <v-text-field v-model="filter.keyword"
                              rounded
                              filled
                              dense
                              append-icon="mdi-magnify"
                              single-line
                              hide-details
                              clearable
                              placeholder="Rechercher..."
                              v-on:keyup.enter="fetchData"/>
              </div>

              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="uploadExcel">
                <v-icon left>mdi-file-excel</v-icon>
                Importer
              </v-btn>

              <TemplateExcel/>

              <FilterDialog @filter="[filterBy($event)]" :filter="filter"/>

            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-0">

              <div v-if="isLoading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </div>

              <div v-else>
                <div v-if="vouchers.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th></th>
                        <th>ID</th>
                        <th>Crée Par</th>
                        <th>Cree le</th>
                        <th>Montant</th>
                        <th>Etat</th>
                        <th>Supplier</th>
                        <th>Service</th>
                        <th>Expiration</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="item in vouchers.data"
                          :key="item.id"
                          :class="getTrClass(item)">

                        <td>
                          <v-checkbox
                              hide-details
                              class="mt-0 pt-0"
                              v-if="item.used === 0"
                              v-model="selectedVouchers"
                              :value="item.id"
                          ></v-checkbox>
                        </td>

                        <td>
                          {{ item.id }}
                        </td>

                        <td>
                          {{ item.user ? item.user.name : "-" }}
                        </td>

                        <td class="text-no-wrap">
                          <v-icon small
                                  :style="{marginTop:'-3px'}"
                                  color="gifty">mdi-clock-outline
                          </v-icon>
                          {{ moment(item.created_at).format('DD/MM/YYYY') }}
                        </td>

                        <td class="font-weight-medium gifty--text">
                          {{ CurrencyFormatting(item.amount) }} DZD
                        </td>

                        <td>
                          <v-chip
                              :color="setStatusColor(item.used)"
                              label
                              small
                              dark
                          >
                            {{ setStatus(item.used) }}
                          </v-chip>
                        </td>

                        <td>
                          {{ item.supplier ? item.supplier.name : "" }}
                        </td>

                        <td>
                          {{ item.service ? item.service.name : "" }}
                        </td>

                        <td class="text-no-wrap">
                          <v-icon small
                                  :style="{marginTop:'-3px'}"
                                  color="gifty">mdi-clock-outline
                          </v-icon>
                          {{ moment(item.expiration_date).format('DD/MM/YYYY') }}
                        </td>

                        <td>
                          <v-menu transition="slide-x-transition"
                                  bottom
                                  right>

                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" :disabled="item.used!==0">
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense>
                              <v-list-item @click="handleUpdate(item)" :disabled="item.used === 1">
                                <v-list-item-icon>
                                  <v-icon>mdi-pencil-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Modifier</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="handleDelete(item.id)"
                                  :disabled="item.used === 1">
                                <v-list-item-icon>
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Supprimer</v-list-item-title>
                              </v-list-item>
                            </v-list>

                          </v-menu>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-divider/>

                  <div class="d-flex justify-space-between pl-4 pt-6 pr-4">

                    <div :style="{width : '80px'}">
                      <v-select
                          outlined
                          v-model="filter.size"
                          dense
                          :items="[5, 10, 20, 30, 40, 50]"
                          @change="onPageChange"
                      ></v-select>
                    </div>

                    <v-spacer/>

                    <v-pagination
                        total-visible="6"
                        v-if="vouchers.total > 15"
                        circle
                        v-model="filter.page"
                        :length="Math.ceil(vouchers.total / filter.size)"
                        @input="onPageChange"
                    ></v-pagination>

                  </div>

                </div>

                <div v-else class="text-center">
                  <v-avatar tile size="200">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                </div>

              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="!isLoading">

    <UpdateDialog ref="updateDialog" @refresh="[refresh()]"/>
    <upload-excel-dialog ref="uploadExcelDialog" @refresh="[refresh()]"/>
      </div>
  </v-container>
</template>

<script>
import Statistiques from "@/views/vouchers/components/Statistiques";
import FilterDialog from "@/views/vouchers/components/FilterDialog";
import CreateDialog from "./components/CreateDialog.vue";
import UpdateDialog from "./components/UpdateDialog.vue";
import {HTTP} from "@/http-common";
import UploadExcelDialog from "./components/UploadExcelDialog.vue";
import TemplateExcel from "./components/TemplateExcel.vue";
import CreateRefillVouchers from "./components/CreateRefillVouchers.vue";

export default {
  components: {
    Statistiques,
    FilterDialog,
    CreateDialog,
    UpdateDialog,
    UploadExcelDialog,
    TemplateExcel,
    CreateRefillVouchers,
  },
  data() {
    return {
      filter: {
        endDate: "",
        keyword: "",
        size: 10,
        page: 1,

        current: 1,
        total: 0,
      },

      delete_id: null,
      selectedVouchers: [],
      action: null,
    };
  },

  methods: {
    fetchData() {
      this.$store.dispatch("fetchVouchers", {
        filter: this.filter,
      });

      this.$store.dispatch("fetchStatistiquesVouchers", {
        filter: this.filter,
      });
    },
    fetchSuppliers() {
      this.$store.dispatch("fetchSuppliers");
    },
    fetchOwnerVouchers() {
      this.$store.dispatch("fetchVoucherOwners");
    },
    onPageChange() {
      this.fetchData();
    },

    setStatusColor(status) {
      status = parseInt(status);

      switch (status) {
        case 0:
          return "warning ";
        case 1:
          return "success ";
      }
    },
    setStatus(status) {
      status = parseInt(status);
      switch (status) {
        case 0:
          return "Non Utilisé";
        case 1:
          return "Utilisé";
      }
    },

    filterBy(filter) {
      this.filter.date = filter.date;
      this.filter.selectedsuppliers = filter.selectedsuppliers;
      this.filter.status = filter.status;
      this.filter.owners = filter.selectedowners;

      this.fetchData();
    },
    refresh() {
      this.fetchData();
    },
    handleDelete(id) {
      this.action = "removeOne";
      this.delete_id = id;
      this.$confirm_dialog = true;
    },
    removeVoucher() {
      HTTP.delete("/manager/vouchers/" + this.delete_id)
          .then(() => {
            this.delete_id = null;
            this.refresh();
          })
          .catch((err) => {
            console.log(err);
          });
    },
    handleUpdate(item) {
      this.$refs.updateDialog.open(item);
    },

    uploadExcel() {
      this.$refs.uploadExcelDialog.open();
    },

    deleteAll() {
      this.action = "removeMany";
      this.$confirm_dialog = true;
    },
    removeAll() {
      HTTP.delete("/manager/vouchers/delete_all", {
        params: {
          ids: this.selectedVouchers,
        },
      })
          .then(() => {
            this.selectedVouchers = [];
            this.refresh();
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getTrClass(item) {
      if (item.expiration_date == null) return "";

      if (this.isInThePast(new Date(item.expiration_date)) && item.used == 0) {
        return "expired";
      }
      return "";
    },
    isInThePast(date) {
      if (date == null) return false;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
    vouchers() {
      return this.$store.getters.getVouchers;
    },
    suppliers() {
      return this.$store.getters.getSuppliers;
    },
  },
  mounted() {
    this.fetchData();
    this.fetchSuppliers();
    this.fetchOwnerVouchers();

    this.$store.dispatch("fetchServices", {
      filter: {},
    });
  },
  watch: {
    $is_confirm: function (el) {
      console.log(el);
      if (el) {
        if (this.action == "removeOne") {
          this.removeVoucher();
        }

        if (this.action == "removeMany") {
          this.removeAll();
        }
        this.action = null;
        this.$is_confirm = false;
      }
    },
  },
};
</script>

<style scoped>
.expired {
  background-color: rgb(255 191 191);
}

.expired td {
  color: white;
}
</style>