<template>

  <v-dialog v-model="downloadCanvaDialog" max-width="500" persistent>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-btn color="primary"
             text
             v-on="{...menu }"
             v-bind="attrs"
      >
        <v-icon left>mdi-cloud-download</v-icon>
        Canvas
      </v-btn>
    </template>

    <v-card>
      <v-card>
        <v-card-title>
          Télécharger le fichier Canvas
          <v-spacer/>
          <v-btn
              icon
              class="v-btn--active"
              color="primary"
              @click="downloadCanvaDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="text-center">
          <p>
            Veuillez Utiliser le canvas pour injecter vos vouchers
          </p>
          <v-btn
              text
              class="text-none mt-3 rounded-lg mr-2"
              @click="downloadCanvaDialog = false"
              large
          >
            <v-icon left>mdi-close</v-icon>
            Fermer
          </v-btn>
          <v-btn
              color="primary"
              @click="downloadCanvas"
              depressed
              class="rounded-lg text-none mt-3"
              large
          >
            <v-icon left>mdi-download</v-icon>
            Télécharger
          </v-btn>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>

</template>

<script>

export default {
  data() {
    return {
      downloadCanvaDialog: false,
    }
  },
  methods: {
    downloadCanvas() {
      window.location.href = process.env.VUE_APP_FILE_URL + "/template_vouchers.xlsx";
    },

    close() {
      this.downloadCanvaDialog = false
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>

<style scoped>
.no-anim:hover {
  transform: none;
}
</style>