<template>
  <v-dialog max-width="500" scrollable v-model="dialog" persistent>
    <!-- <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on: tooltip }">
            
            <v-btn color="primary" rounded depressed v-on="{ ...tooltip, ...menu }" v-bind="attrs">
                <v-icon left>mdi-plus</v-icon>
                Ajouter
            </v-btn>

          </template>
          <span>Nouveau voucher</span>
        </v-tooltip>
      </template> -->
    <v-card>
      <v-progress-linear
        v-if="isLoading"
        color="primary"
        indeterminate
        reverse
      ></v-progress-linear>
      <v-card-title>
        <v-icon left>mdi-tune</v-icon>
        Modification d'un Voucher
        <v-spacer />
        <v-btn small outlined text class="mr-2 ml-2 no-anim" @click="clearForm"
          >Effacer</v-btn
        >
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-4"  style="overflow-y: scroll; max-height: 450px;">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-img
              max-height="80"
              max-width="80"
              :src="require('@/assets/voucher.png')"
            ></v-img>
          </v-col>

          <v-col cols="12" v-if="error">
            <v-alert border="left" color="error" dark>
              {{ error }}
              <ul v-if="errors">
                <li
                  v-for="(value, key, index) in errors"
                  :key="`${key}-${index}`"
                >
                  {{ value[0] }}
                </li>
              </ul>
            </v-alert>
          </v-col>
          <!-- <v-col cols="12">
              <v-select
                        prepend-icon="mdi-filter-outline"
                        chips deletable-chips small-chips dense clearable
                        :items="suppliers"
                        v-model="form.supplier_id"
                        :item-text="'name'"
                        :item-value="'id'"
                        label="Suppliers..." hide-details>
                        
                        <template v-slot:item="slotProps">
                            <div style="width: 50px;">
                                 <img :src="require('@/assets/'+slotProps.item.name.toLowerCase().replace(':', '')+'.png')" 
                                max-height="25"
                                max-width="25" width="25" height="25" class="p-2" />
                            </div>
                           
                            {{slotProps.item.name}}
                        </template>

            </v-select>
            </v-col> -->

          <v-col cols="12">
            <v-select
              prepend-icon="mdi-filter-outline"
              chips
              deletable-chips
              small-chips
              dense
              clearable
              :items="services"
              v-model="form.service_id"
              :item-text="'name'"
              :item-value="'id'"
              label="Service..."
              hide-details
            >
              <template v-slot:item="slotProps">
                <div style="width: 50px">
                  <img
                    :src="getImageLink(slotProps.item.image)"
                    max-height="25"
                    max-width="25"
                    width="25"
                    height="25"
                    class="p-2"
                  />
                </div>

                {{ slotProps.item.name }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.code"
              prepend-icon="mdi-ticket-percent-outline"
              type="text"
              dense
              clearable
              label="Code.."
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.amount"
              prepend-icon="mdi-currency-usd "
              type="number"
              dense
              clearable
              label="Montant.."
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.displayed_amount"
              prepend-icon="mdi-currency-usd "
              dense
              clearable
              label="Montant à afficher.."
              @focus="
                errors && errors.displayed_amount
                  ? (errors.displayed_amount = '')
                  : false
              "
              :error-messages="errors.displayed_amount"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.expiration_date"
              prepend-icon="mdi-calendar"
              append-icon="mdi-calendar"
              type="date"
              dense
              clearable
              label="Date d’expiration.."
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="isDisabled"
          depressed
          block
          @click="handleUpdate"
        >
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      services: [],
      dialog: false,
      form: {
        id: null,
        code: "",
        supplier_id: null,
        service_id: null,
        amount: null,
        displayed_amount: null,
        expiration_date: null,
      },
      isLoading: false,
      error: "",
      errors: [],
    };
  },
  methods: {
    getImageLink(file) {
      return process.env.VUE_APP_FILE_URL + file;
    },

    open(voucher) {
      this.form = {
        id: voucher.id,
        code: voucher.code,
        amount: voucher.amount,
        displayed_amount: voucher.displayed_amount,
        supplier_id: voucher.supplier_id,
        service_id: voucher.service_id,
        expiration_date: voucher.expiration_date,
      };

      this.dialog = true;
    },
    handleUpdate() {
      //this.dialog = false
      //this.$emit('filter', this.filter)
      this.isLoading = true;
      this.errors = [];
      this.err = "";
      HTTP.put("/manager/vouchers/" + this.form.id, this.form, { params: {} })
        .then((res) => {
          console.log(res.data);

          this.isLoading = false;
          this.close();
          this.$emit("refresh");
        })
        .catch((err) => {
          this.isLoading = false;

          this.error = err.response.data.message;
          this.errors = err.response.data.errors;
        });
    },
    clearForm() {
      this.form = {};
    },
    close() {
      this.dialog = false;
    },

    getServices() {
      HTTP.get("/service/voucher", { params: {} })
        .then((res) => {
          this.services = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    isDisabled() {
      return (
        Object.values(this.form).every(
          (x) => x === null || x === "" || x.length === 0
        ) || this.isLoading
      );
    },
    suppliers() {
      return this.$store.getters.getSuppliers;
    },
    // services() {
    //   return this.$store.getters.getServices.data;
    // }
  },
  mounted() {
    this.getServices();
  },
};
</script>
  
  <style scoped>
</style>
  
  <style scoped>
.no-anim:hover {
  transform: none;
}
</style>